/* index.css */

/* Global Styles */
body {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Nunito Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

p {
  margin: 0px;
}

a {
  text-decoration: none;
  color: #6941c6;
  font-weight: 600;
}

body::-webkit-scrollbar {
  width: 1px; /* Width of the scrollbar */
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

/* Typography */
.sectionTitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 36px;
  letter-spacing: -0.03em;
  text-align: left;
  margin-bottom: 5px;
}

.sectionSubTitle {
  font-family: "Nunito Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
  color: #3d5b64;
}

.fieldLabel {
  font-size: 14px;
  font-weight: bold;
}

.boxTitle {
  font-size: 18px;
  font-weight: 600;
  /* margin-bottom: 20px; */
}

.chartTitle {
  font-size: 18px;
  font-weight: 600;
}

.tagStyle {
  padding: 3px;
  border: 1px solid #f2f2f2;
  border-radius: 10px;
  text-align: center;
  color: #000;
  background-color: #f3f3f3;
}

.viewDetail {
  border: 1px solid #203339;
  padding: 6px 9px;
  border-radius: 4px;
  color: #203339 !important;
}

/* Custom Tooltip */
.custom-tooltip {
  background-color: white;
  border: none;
  border-radius: 10px;
  padding: 10px !important;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.custom-tooltip .label {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 14px;
  color: black;
}

.custom-tooltip p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  color: black;
}

/* Icon Background */
.iconBackgroundBg {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

/* Spacing Utilities */
.wFull {
  width: 100%;
}

.wHalf {
  width: 50%;
}

.mtLarge {
  margin-top: 30px;
}

.mbLarge {
  margin-bottom: 30px;
}

.mlLarge {
  margin-left: 30px;
}

.mrLarge {
  margin-right: 30px;
}

.mbZero {
  margin-bottom: 0px !important;
}

.mbFive {
  margin-bottom: 5px !important;
}

/* Flex and Grid Utilities */
.dfaCenter {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.dfsBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dGridCenter {
  display: grid;
  place-items: center;
}

/* Buttons and Inputs */
.customAntInput {
  border-radius: 22px !important;
  height: 50px;
}

.custom-ant-button {
  height: 50px !important;
}

.ant-btn-primary {
  box-shadow: none !important;
}

.ant-btn-primary:disabled {
  background-color: #569194;
  color: white;
  border: none;
}

.ant-btn-default.ant-btn-dangerous {
  border-color: #ff5454 !important;
}

.ant-btn-default.ant-btn-dangerous:hover {
  border-color: #ff5454 !important;
}

.gradientButton {
  background: linear-gradient(to right, #008b82, #009fa2) !important;
  color: white;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
}

.gradientButtonRed {
  background: linear-gradient(to right, #ff5454, #ff87ad) !important;
  color: white !important;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border-radius: 4px;
  border: none !important;
}

.gradientButton:hover {
  color: white !important;
}

.gradientButtonRed:hover {
  color: white !important;
}

.gradientButton:disabled {
  color: white !important;
}

.ant-input-search-button {
  color: white !important;
  background-color: black !important;
}

/* Icons */
.importantIcon {
  object-fit: contain;
  width: 10px;
  height: 17px;
  margin-right: 3px;
}

/* Miscellaneous */
.addBox {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.addBoxWhite {
  display: grid;
  place-items: center;
  width: 170px;
  height: 40px;
  color: black;
  background-color: #fff;
}

.activeTopNavLi {
  border-bottom: #3ab44d 3px solid;
}

.modalCloseIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  z-index: 10;
}

.recharts-legend-item-text {
  color: black !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Ant Design Components */

/* Tabs */
.ant-tabs-ink-bar {
  background-color: #179dba !important;
  height: 4px !important;
}

.ant-tabs-tab-btn {
  font-weight: 600 !important;
}

/* Modals */
.ant-modal-content {
  border-radius: 10px !important;
}

.viewDocs .ant-modal-content {
  width: 520px !important;
  border-radius: 23px !important;
}

.editEmployer .ant-modal-content {
  width: 620px !important;
  border-radius: 10px !important;
}

.policyUtilisationModal .ant-modal-close {
  display: none !important;
}

.ant-modal-confirm-btns .ant-btn-default {
  color: black !important;
}

.ant-modal-confirm-btns .ant-btn-default:hover {
  border-color: #3ab44d !important;
}

.ant-modal-confirm-btns .ant-btn-primary {
  background-color: #3ab44d !important;
}

/* Upload */
.ant-upload.ant-upload-drag {
  border: 1px solid #d9d9d9;
}

.ant-upload-list {
  display: flex !important;
  justify-content: flex-start !important;
  max-width: 400px !important;
}

/* Pagination */
.ant-pagination.ant-pagination-mini .ant-pagination-item {
  border-radius: 50%;
  background-color: #3ab44d;
}

.ant-pagination-item-active a {
}

.ant-pagination-options {
  display: none !important;
}

.ant-picker-suffix .anticon-calendar {
  color: black;
}

/* Select */
.ant-select .ant-select-arrow {
  color: #00112c !important;
}

.ant-select-arrow {
  color: #3ab44d !important;
}

.support .ant-select-selection-placeholder {
  font-size: 14px !important;
}

.medicalFacilitySelect .ant-select-selector {
  border: none !important;
}

/* Input */
.inpatientDetail .ant-input {
  background-color: #f8f8f8;
}

.medicalSearchInput {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}

/* Radio */
.ant-radio-wrapper-checked span {
  color: #3ab44d !important;
  font-weight: 500;
}

/* Divider */
.ant-divider-horizontal {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

/* Collapse */
.ant-collapse-borderless {
  background-color: #ffffff80;
}

/* Table */
/* Ant table CSS customization starts here */
.ant-table-thead > tr > th {
  background-color: #f7f8fa !important;
  font-weight: bold;
}

/* Add borders to all cells */
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  border: 0.5px solid #d4dbe0 !important;
}

.ant-table-cell {
  font-size: 13px !important;
}

.ant-table-filter-column {
  justify-content: flex-start !important;
}

/* Ant table CSS customization ends here */

/* Icons */
.anticon-delete svg {
  color: red;
  width: 1rem !important;
  height: 1rem !important;
}

/* Form */
.ant-form-item-explain-error {
  margin-left: 10px;
}

/* Misc */
.recharts-legend-item-text {
  color: black !important;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-active {
  background-color: transparent !important;
}

@media print {
  .printButton {
    display: none !important;
  }
}

.ag-cell {
  padding: 0px !important;
}
